import React from 'react'
import { BurgerMenu, NavigationContainer } from './styles'
import { BurgerMenuIcon } from '../icons'
import { Colors } from '../../core/colors'
import { graphql, StaticQuery } from 'gatsby'
import NavHead from './navHead'
import NavBar from './navBar'


const NavigationQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                company {
                    tel
                    email
                }
                navigation {
                    link
                    name
                }
            }
        }
    }
`

export default class Navigation extends React.Component {

  constructor(props) {
    super(props)
    this.initState(props)
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  componentDidMount() {
    this.initEventListener();
  }

    initEventListener() {
    window.addEventListener('scroll', () => {
      if(this.state.fixed && window.scrollY >= 70){
        return;
      }

      this.setState(() => ({
        fixed: window.scrollY >= 70,
      }))
    })
  }

  initState(props) {
    this.state = {
      open: false,
      fixed: false,
      activatedRoute: props.activatedRoute,
    }
  }

  handleMenuClick() {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  renderNavigation(data) {
    return (
      <NavigationContainer>
        <NavHead title={data.title} company={data.company}/>

        <BurgerMenu onClick={this.handleMenuClick}>
          <BurgerMenuIcon color={Colors.text} size={'2rem'}/>
        </BurgerMenu>

        <NavBar fixed={this.state.fixed} opened={this.state.open}
                activatedRoute={this.state.activatedRoute} navItems={data.navigation}/>

      </NavigationContainer>)
  }


  render() {
    return <StaticQuery
      query={NavigationQuery}
      render={data => this.renderNavigation(data.site.siteMetadata)}
    />
  }

}

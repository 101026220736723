import * as React from 'react'
import Footer from '../components/shared/footer'
import SEO from '../components/shared/seo'
import { GlobalStyle } from './styles'
import Navigation from '../components/shared/navigation'


class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <>
        <GlobalStyle/>
        <SEO title="Amann Massiv- & Betonbau"
             description="Amann Massiv- & Betonbau | Beraten. Bauen. Begleiten. Ihr starker Partner am Bau aus der Region Ostbayern"
             lang="de"/>
        <Navigation activatedRoute={this.props.location}/>
        {children}
        <Footer/>
      </>
    )
  }
}

export default Layout

import React from 'react'

import { BrandContainer, BrandImage } from './styles'
import { graphql, StaticQuery } from 'gatsby'

export default class Logo extends React.Component {

  render() {
    return (
      <StaticQuery query={graphql`
        query {      
          logo: file(name: {eq: "logo"}) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      srcSet
                  }
              }
          }
        }`
      } render={ data =>
        <>
          <BrandContainer href={'/'}>
            <BrandImage srcSet={data.logo.childImageSharp.fluid.srcSet} alt={'Amann Massiv- & Betonbau'}/>
          </BrandContainer>
        </>
      }/>
    )
  }
}

import React from 'react'
import {
  AboutContainer,
  Address,
  BrandContainer,
  BrandImage,
  Contact,
  FooterContainer,
  FooterWrapper,
  Headline,
  LegalContainer,
  LegalLink,
  LegalWrapper,
  OpeningHours,
  OpeningHoursContainer,
  Place,
  Street,
} from './styles'
import { graphql, StaticQuery } from 'gatsby'


export default class Footer extends React.Component {

  constructor(props) {
    super(props)
  }

  renderFooter({ logo }) {
    return (
      <>
        <FooterWrapper>
          <FooterContainer>
            <AboutContainer>
              <BrandContainer>
                <BrandImage alt={'Amann Massiv- & Betonbau'}
                            srcSet={logo.childImageSharp.fluid.srcSet}/>
              </BrandContainer>
              <Address>
                <Street>Pfatterstraße 1</Street>
                <Place>93098 Mintraching / Tiefbrunn</Place>
              </Address>
              <Contact href={'tel:09406 9403-0'}>Tel.: 09406 9403-0</Contact>
              <Contact href={'fax:09406 9403-0'}>Fax.: 09406 9403-20</Contact>
              <Contact href={'mailto:service@amann-betonbau.de'}>service@amann-betonbau.de</Contact>
            </AboutContainer>

            <OpeningHoursContainer>
              <Headline>Geschäftszeiten</Headline>
              <OpeningHours>
                <tbody>
                <tr>
                  <td>Montag bis Donnerstag</td>
                  <td>7.30 Uhr - 17.00 Uhr</td>
                </tr>
                <tr>
                  <td>Freitag</td>
                  <td>7.30 Uhr - 13.00 Uhr</td>
                </tr>
                </tbody>
              </OpeningHours>
            </OpeningHoursContainer>
          </FooterContainer>
        </FooterWrapper>
        <LegalWrapper>
          <LegalContainer>
            <LegalLink to={'/legal/imprint'}>Impressum</LegalLink>
            <LegalLink to={'/legal/gdpr'}>Datenschutz</LegalLink>
			<a style={{color:"rgb(68,68,68)" , marginRight:"20px"}} href="/downloads/agb.pdf">AGB</a>
			<a style={{color:"rgb(68,68,68)" , marginRight:"20px"}} href="/downloads/whg.pdf">WHG Zertifikat</a>
          </LegalContainer>
        </LegalWrapper>
      </>
    )
  }

  render() {
    return (
      <StaticQuery query={FooterQuery} render={data => this.renderFooter(data)}/>
    )
  }

}

export const FooterQuery = graphql`{
    logo: file(name: {eq: "logo-light"}) {
        childImageSharp {
            fluid(maxWidth: 200) {
                srcSet
            }
        }
    }
}
`

import styled from 'styled-components'
import { Device } from '../../core/responsive'
import { motion } from 'framer-motion'
import { Fonts } from '../../core/fonts'
import { Colors } from '../../core/colors'
import { Container, Wrapper } from '../../core/layout'

export const NavigationContainer = styled(Wrapper)`
  align-items: center;
  position: absolute;
  padding: 20px 0;
  z-index: 20;
`

export const NavigationWrapper = styled(Wrapper)`
  align-items: center;
  position: absolute;
  padding: 20px 0;
  z-index: 20;

  &.navigation-bar {
    padding: 10px 0;
    z-index: 10;
    top: ${props => props.fixed ? '0' : '70px'};
    position: ${props => props.fixed ? 'fixed' : 'absolute'};
  }
`

export const NavigationList = styled(Container)`
  display: ${props => (props.opened ? 'flex' : 'none')};

    @media (${Device.laptop}){
        display: flex;
    } 
`

export const BurgerMenu = styled.div`
  position: fixed;
  right: 20px;
  z-index: 20;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  
  @media (${Device.laptop}) {
    display: none;
  }
`


export const NavList = styled(motion.ul)`
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
    
    @media (${Device.laptop}){
      flex-direction: row;
    }
`

export const NavigationItem = styled(motion.a)`
  padding: 24px 16px;

  text-transform: uppercase;
  display: flex;
  font-family: ${Fonts.default};
  font-weight: 700;
  font-size: 14px;
  color: ${props => (props.active ? Colors.primary : Colors.text)};

  &:hover,
  &:focus{
    color: ${Colors.primary};
  }

  @media (${Device.laptop}){
    display: block;
    padding: 14px 16px;
  }
`

import React from 'react'
import Logo from '../logo'
import Contact, { ContactTypes } from '../contact'
import { Container } from '../../core/layout'

const NavHead = (props) => {
  const { title, company } = props
  return (
    <Container>
      <Logo brand={title}/>
      <Contact type={ContactTypes.Phone} label={'Telefon'} children={company.tel}
               href={company.tel}/>
      <Contact type={ContactTypes.Mail} label={'E-Mail'} children={'Kontaktieren Sie uns'}
               href={company.email}/>
    </Container>
  )
}


export default NavHead

import React from 'react';
import { NavigationItem, NavigationList, NavigationWrapper, NavList } from './styles';

const NavBar = (props) => {
  const { navItems, fixed, opened, activatedRoute } = props;

  const renderNavigationItems = (navigationItems, activatedRoute) => {
    return navigationItems.map((item, index) => {
      return (
        <li key={index}>
          <NavigationItem active={activatedRoute === item.link}
                          href={item.link}>
            {item.name}
          </NavigationItem>
        </li>
      );
    });
  };

  return (
    <NavigationWrapper className={'navigation-bar'} fixed={fixed}>
      <NavigationList opened={opened}>
        <NavList>
          {renderNavigationItems(navItems, activatedRoute)}
        </NavList>
      </NavigationList>
    </NavigationWrapper>
  );
};


export default NavBar;

import React from 'react';
import { Colors } from '../../core/colors';
import { MailIcon, PhoneIcon } from '../icons';
import { ContactContainer, ContactInformation, ContactLabel, ContactLink } from './styles';


export const ContactTypes = {
  Phone: 'tel',
  Mail: 'mailto'
};

const Contact = ({ label, type, href, children }) => {

  const generateIcon = (type) => {
    switch (type) {
      case ContactTypes.Phone:
        return <PhoneIcon size={'2rem'} color={Colors.primary}/>;
      case ContactTypes.Mail:
        return <MailIcon size={'2rem'} color={Colors.primary}/>;
    }
  };


  return (
    <ContactContainer>
      {generateIcon(type)}
      <ContactInformation>
        <ContactLabel>{label}</ContactLabel>
        <ContactLink href={type + ':' + href}>{children}</ContactLink>
      </ContactInformation>
    </ContactContainer>
  );
};

export default Contact;

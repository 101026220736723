import styled from 'styled-components'
import { Device } from '../../core/responsive'
import { Colors } from '../../core/colors'
import { Fonts } from '../../core/fonts'

export const ContactContainer = styled.div`
   display: none;
   align-items: center;
   max-height: 100%;
   padding: 0 20px;
   color: ${Colors.black};
       
   @media (${Device.laptop}){
    display: flex;
   }
   
   &:last-of-type{
    border-right: none;
   }
`

export const ContactInformation = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
`

export const ContactLabel = styled.label`
    font-family: ${Fonts.headline}, sans-serif;
    font-weight: 700;
    font-size: .8rem;
    text-transform: uppercase;
    padding-bottom: 5px;
`

export const ContactLink = styled.a`
    font-family: ${Fonts.headline}, sans-serif;
    font-size: .9rem;
    opacity: .8;
    
    &:hover{
      opacity: 1;
    }
`

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const BrandContainer = styled.a`
    display: flex;
    position: relative;
    flex: 1 25%;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

export const BrandImage = styled(motion.img)`
    max-width: 150px;
    margin-right: 10px;  
`;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Colors } from '../../core/colors';

const IconComponent = styled(motion.svg)`
    fill: ${props => props.color};
    vertical-align: middle;
`;

const Icon = (props) => (
  <IconComponent
    {...props}
    height={props.height || props.size}
    viewBox={props.viewBox || '0 0 20 20'}>
    <title>{props.title}</title>
    {props.children}
  </IconComponent>
);


export const BurgerMenuIcon = props => (
  <Icon title="Burger Menu icon" {...props}>
    <path d="M16.4,9H3.6C3.048,9,3,9.447,3,10c0,0.553,0.048,1,0.6,1h12.8c0.552,0,0.6-0.447,0.6-1S16.952,9,16.4,9z
	 M16.4,13H3.6C3.048,13,3,13.447,3,14c0,0.553,0.048,1,0.6,1h12.8c0.552,0,0.6-0.447,0.6-1S16.952,13,16.4,13z M3.6,7h12.8
	C16.952,7,17,6.553,17,6s-0.048-1-0.6-1H3.6C3.048,5,3,5.447,3,6S3.048,7,3.6,7z"/>
  </Icon>
);

export const PhoneIcon = props => (
  <Icon title="Phone icon" {...props}>
    <path d="M11.229,11.229c-1.583,1.582-3.417,3.096-4.142,2.371c-1.037-1.037-1.677-1.941-3.965-0.102
	c-2.287,1.838-0.53,3.064,0.475,4.068c1.16,1.16,5.484,0.062,9.758-4.211c4.273-4.274,5.368-8.598,4.207-9.758
	c-1.005-1.006-2.225-2.762-4.063-0.475c-1.839,2.287-0.936,2.927,0.103,3.965C14.324,7.812,12.811,9.646,11.229,11.229z"/>
  </Icon>
);

export const MailIcon = props => (
  <Icon title="Mail icon" {...props}>
    <path d="M1.574,5.286c0.488,0.262,7.248,3.894,7.5,4.029C9.326,9.45,9.652,9.514,9.98,9.514
     c0.328,0,0.654-0.064,0.906-0.199s7.012-3.767,7.5-4.029C18.875,5.023,19.337,4,18.44,4H1.521C0.624,4,1.086,5.023,1.574,5.286z
     M18.613,7.489c-0.555,0.289-7.387,3.849-7.727,4.027s-0.578,0.199-0.906,0.199s-0.566-0.021-0.906-0.199S1.941,7.777,1.386,7.488
     C0.996,7.284,1,7.523,1,7.707S1,15,1,15c0,0.42,0.566,1,1,1h16c0.434,0,1-0.58,1-1c0,0,0-7.108,0-7.292S19.004,7.285,18.613,7.489z"/>
  </Icon>
);

export const HeaderIcon = props => (
  <Icon title="Header icon" {...props}>
    <polygon points="0,50 50,0 100,50
                               75,50 50,25 25,50"/>

    <polygon fill={props.diamondColor} points="35,50 50,35 65,50 50,65"/>
  </Icon>
);

export const DecorateIcon = props => (
  <Icon title="Header icon" {...props}>
    <polygon opacity={props.opacity} fill={Colors.primary} points="0,50 50,0 100,50
                               75,50 50,25 25,50"/>

    <polygon opacity={props.opacity} fill={Colors.text} points="35,50 50,35 65,50 50,65"/>
  </Icon>
);

export const MouseIcon = props => (
  <Icon title="Scroll icon" {...props}>
    <path
      d="M12.505,8.698L10,11L7.494,8.698c-0.198-0.196-0.518-0.196-0.718,0c-0.197,0.196-0.197,0.515,0,0.71
       l2.864,2.807c0.199,0.196,0.52,0.196,0.717,0l2.864-2.807c0.199-0.195,0.198-0.514,0-0.71C13.024,8.502,12.704,8.502,12.505,8.698z
       M10,0.4c-5.302,0-9.6,4.298-9.6,9.6c0,5.303,4.298,9.6,9.6,9.6s9.6-4.297,9.6-9.6C19.6,4.698,15.302,0.4,10,0.4z M10,18.354
        c-4.615,0-8.354-3.74-8.354-8.354S5.385,1.646,10,1.646c4.613,0,8.354,3.74,8.354,8.354S14.613,18.354,10,18.354z"/>
  </Icon>
);

import { createGlobalStyle } from 'styled-components'
import { Fonts } from '../components/core/fonts'
import { Colors } from '../components/core/colors'


export const CookieStyle = {
  position: 'fixed',
  display: 'block',
  background: '#fff',
  boxShadow: '0 15px 45px -9px rgba(0,0,0,.2)',
  color: `${Colors.black}`,
  left: 'auto',
  textAlign: 'center',
}

export const AcceptCookieButtonStyle = {
  position: 'relative',
  background: `${Colors.primary}`,
  color: `${Colors.white}`,
  boxShadow: '0 15px 45px -9px rgba(0,0,0,.2)',
  padding: '10px 24px',
  borderRadius: '3px',
  border: 'none',
  textTransform: 'uppercase',
  fontWeight: '600',
  margin: '0 0 10px 0',
  fontFamily: `${Fonts.headline}`,
}

export const GlobalStyle = createGlobalStyle`

    :root {
      font-size: 100%;
    
      html, body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin: 0;
        padding: 0;
        /* stylelint-disable-next-line */
        font-size: 16px;
        scroll-behavior: smooth;
        font-family: ${Fonts.default};
        background-color: ${Colors.white};
        background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23eeeeee' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
      
    }
`

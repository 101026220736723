import styled from 'styled-components'
import { Colors } from '../../core/colors'
import { Device } from '../../core/responsive'
import { Fonts } from '../../core/fonts'
import { Link } from 'gatsby'

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${Colors.footer};
  justify-content: center;
`

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 920px;
  padding: 30px;
  flex-direction: column;
  
  @media (${Device.laptop}){
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const AboutContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1 50%;
  margin-bottom: 20px;
`


export const BrandContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`

export const BrandImage = styled.img`
    max-width: 150px;
    margin-right: 10px; 
    height: auto;
`

export const Brand = styled.h1`
    font-weight: 900;
    font-size: 1.5rem;
    font-family: ${Fonts.default};
    font-stretch: condensed;
    text-transform: uppercase;
    color: ${Colors.textLight};
`

export const LegalWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${Colors.primary};
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  justify-content: center;
`

export const LegalContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 920px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  
  @media (${Device.laptop}){
    margin: 0;
    padding-left: 0;
  }
`

export const LegalLink = styled(Link)`
    color: ${Colors.text};
    margin-right: 20px;
    
    &:last-of-type{
      margin-right: 0;
    }
    
    &:hover,
    &:focus{
      color: ${Colors.black}
    }
`

export const Address = styled.div`
  margin-bottom: 20px;
  line-height: 1.2rem;
`

export const Street = styled.p`
    color: ${Colors.white};
    font-weight: 600;
`

export const Place = styled.p`
    color: ${Colors.white};
    font-weight: 600;
`

export const Contact = styled.a`
    color: ${Colors.white};
    cursor: pointer;
    margin-bottom: 8px;
    
    &:hover{
      color: ${Colors.primary};
    }
`


export const OpeningHoursContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1 50%;
`

export const Headline = styled.div`
  color: ${Colors.white};
  font-size: 1.4rem;
  font-stretch: condensed;
  font-weight: 600;
  margin-bottom: 12px;
`

export const OpeningHours = styled.table`
   align-items: center;
   color: ${Colors.white};
   width: 100%;
   border-spacing: 0 10px;
   border-collapse: inherit;
`

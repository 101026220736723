export const Colors = {
  primary: '#2CA067',

  text: '#444',
  black: '#000',
  gray: '#ccc',
  textLight: '#ccc',

  white: '#fff',

  footer: '#333',
  legal: '#111'
};

import styled from 'styled-components'
import { Device } from './responsive'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 920px;
  margin: 0 10px;

  @media (${Device.laptop}){
    margin: 0;
  }
`
